import apiClient from './apiClient'
import { Suggestion } from "@/models/suggestions"
import { CollectionResourceDoc } from '@/models/jsonapi'

const resource = "/suggestions"

export default {
  async list(queryParams?: { [key:string]: any  }) {
    const response = await apiClient.get<CollectionResourceDoc<string, Suggestion>>(resource, { params: queryParams })
    return response.data
  },
  async get(id: string, queryParams?: { [key:string]: any  }) {
    let params = {
      'filter[id]': id
    }
    if (queryParams) {
      params = {
        ...params,
        ...queryParams
      }
    }
    const response = await apiClient.get<CollectionResourceDoc<string, Suggestion>>(resource, { params: params })
    return response.data
  },
  async create(data?: any) {
    const response = await apiClient.post(resource, data)
    return response.data
  },
  async edit(id: string, data?: any) {
    const response = await apiClient.patch(`${resource}/${id}`, data)
    return response.data
  },
  async delete(id: string) {
    const response = await apiClient.delete(`${resource}/${id}`)
    return response.data
  },
  async presentationMailData(suggestionIds:string[]) {
    const params = {ids: suggestionIds.join()}
    const response = await apiClient.get(resource+'/presentation_mail_data', { params: params })
    return response
  }
}
